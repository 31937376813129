import { Component, OnInit } from "@angular/core";
import { MobileService } from "../../shared/services/mobile.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})

/**
 * Footer component
 */
export class FooterComponent implements OnInit {
  // set the currenr year
  year: number = new Date().getFullYear();

  constructor(private readonly mobileService: MobileService) {}

  ngOnInit() {}

  get isMobile() {
    return this.mobileService.isMobile;
  }
}
