import { Injectable } from "@angular/core";

import { getFirebaseBackend } from "../../authUtils";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  user: any;

  constructor() {}

  /**
   * Returns the current user
   */
  public currentUser() {
    return getFirebaseBackend().getAuthenticatedUser();
  }

  public getToken() {
    return JSON.parse(localStorage.getItem("token"));
  }

  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
   */
  login(email: string, password: string) {
    return getFirebaseBackend()
      .loginUser(email, password)
      .then((response: any) => {
        const user = response;
        return user;
      });
  }

  /**
   * Performs the register
   * @param email email
   * @param password password
   */
  register(email: string, password: string) {
    return getFirebaseBackend()
      .registerUser(email, password)
      .then((response: any) => {
        const user = response;
        return user;
      });
  }

  /**
   * Reset password
   * @param email email
   */
  resetPassword(email: string) {
    return getFirebaseBackend()
      .forgetPassword(email)
      .then((response: any) => {
        const message = response.data;
        return message;
      });
  }

  /**
   * Login using Google Popup Window
   */
  loginWithGoogle() {
    return getFirebaseBackend()
      .googleAuth()
      .then((response: any) => {
        const user = response;
        return user;
      });
  }

  /**
   * Login Automatic Using JWT
   */
  loginWithCustomToken(token: string) {
    return getFirebaseBackend()
      .customTokenAuth(token)
      .then((response) => {
        const user = response;
        return user;
      });
  }

  /**
   * Logout the user
   */
  logout() {
    // logout the user
    getFirebaseBackend()
      .logout()
      .then(() => {
        localStorage.clear();
      });
  }
}
