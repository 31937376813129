<header *ngIf="!isMobile" id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-lg">
            <img
              src="assets/zafe/Logo/PNG/tecnotrust_logo_largo.png"
              alt=""
              height="25"
            />
          </span>
          <span class="logo-sm">
            <img
              src="assets/zafe/Logo/PNG/tecnotrust_logo.png"
              alt=""
              height="17"
            />
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-lg">
            <img
              src="assets/zafe/Logo/PNG/tecnotrust_logo_largo.png"
              alt=""
              height="40"
            />
          </span>
          <span class="logo-sm">
            <img
              src="assets/zafe/Logo/PNG/tecnotrust_logo.png"
              alt=""
              height="40"
            />
          </span>
        </a>
      </div>

      <button
        type="button"
        class="btn btn-sm px-3 font-size-16 header-item"
        id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item"
          ngbDropdownToggle
          id="page-header-user-dropdown"
        >
          <img
            class="rounded-circle header-profile-user"
            alt="Avatar"
            src="assets/img/avatar_user.svg"
          />
          <span class="d-none d-xl-inline-block ms-1">{{
            userData.email
          }}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a
            class="dropdown-item text-danger"
            href="javascript: void(0);"
            (click)="logout()"
            ><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ "HEADER.LOGIN.LOGOUT" | translate }}</a
          >
        </div>
      </div>
    </div>
  </div>
</header>
