<div class="container my-5 text-center rounded p-3 shadow bg-white">
  <img
    class="img-fluid mb-5"
    src="assets/avatars/avatar1.svg"
    alt=""
    [width]="400"
  />
  <div class="mb-5 fs-3 fw-bold" role="alert">Próximamente...</div>
  <!-- <button type="button" class="btn btn-danger w-50" (click)="eliminarCuenta()">
    Eliminar esta cuenta
  </button> -->
</div>
