import Swal from "sweetalert2";
import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../../core/services/auth.service";

@Component({
  selector: "app-comming-soon",
  templateUrl: "./comming-soon.component.html",
  styleUrls: ["./comming-soon.component.scss"],
})
export class CommingSoonComponent implements OnInit {
  constructor(private readonly authService: AuthenticationService) {}

  ngOnInit(): void {}

  eliminarCuenta() {
    Swal.fire({
      title: "Eliminar Cuenta",
      text: "Estas seguro que quieres eliminar esta cuenta!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Deleted!",
          "Lamentamos que te vayas, hemos iniciado el proceso de eliminación de tu cuenta.",
          "success"
        );
      }
    });
  }
}
