// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // firebaseConfig: {
  //   // Mirror (DEV)
  //   apiKey: "AIzaSyAmpghl-V2DiywEBafMCDi_w0Ur-lJwKA8",
  //   authDomain: "mirror-panel-dev-c2699.firebaseapp.com",
  //   databaseURL: "https://mirror-panel-dev-c2699.firebaseio.com",
  //   projectId: "mirror-panel-dev-c2699",
  //   storageBucket: "mirror-panel-dev-c2699.appspot.com",
  //   messagingSenderId: "712296229625",
  //   appId: "1:712296229625:web:d77900821aa0da0dd39028",
  //   measurementId: "G-PNP045L667",
  // },
  firebaseConfig: {
    // Trust (Prod)
    apiKey: "AIzaSyAjMaTdEUf-acVb3lxsd6SJ8C1ocUEEYa0",
    authDomain: "trust-11b7d.firebaseapp.com",
    databaseURL: "https://trust-11b7d.firebaseio.com",
    projectId: "trust-11b7d",
    storageBucket: "trust-11b7d.appspot.com",
    messagingSenderId: "559041043219",
    appId: "1:559041043219:web:41504232bf1c679ad558e6",
    measurementId: "G-EDPLYFRDMM",
  },
  stripePlus: {
    mensual_url: {
      one: "https://buy.stripe.com/test_7sI8yz4lP1mS8p24gh",
      "two-four": "https://buy.stripe.com/test_28o4ijcSl5D80WAeUX",
      five: "https://buy.stripe.com/test_00gg1105z1mSdJm004",
    },
    anual_url: {
      one: "https://buy.stripe.com/test_cN26qr2dH2qW6gUbIK",
      "two-four": "https://buy.stripe.com/test_00g2ab6tX0iObBe149",
      five: "https://buy.stripe.com/test_5kA3ef9G9aXsgVy14a",
    },
  },
  production: false,
  defaultauth: "firebase",
  mapsKey: "AIzaSyCq5xgK_VQ4N5-yoO2kTd7qry9QoM7cMIU",
  fakeUid: "1G2OVu54edRcsTstXV53SRQStWH3",
  cloudFunctionsURL:
    "https://us-central1-trust-11b7d.cloudfunctions.net/server",
  cloudFunctionsNoServerURL:
    "https://us-central1-trust-11b7d.cloudfunctions.net",
  // cloudFunctionsURL:
  //   "https://us-central1-mirror-panel-dev-c2699.cloudfunctions.net/server",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
