import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "./core/guards/auth.guard";
import { LayoutComponent } from "./layouts/layout.component";
import { Page404Component } from "./extrapages/page404/page404.component";
import { CommingSoonComponent } from "./extrapages/comming-soon/comming-soon.component";

const routes: Routes = [
  {
    path: "account",
    loadChildren: () =>
      import("./account/account.module").then((m) => m.AccountModule),
  },
  {
    path: "checkout",
    loadChildren: () =>
      import("./pages/activate-plan/activate-plan.module").then(
        (m) => m.ActivatePlanModule
      ),
  },
  {
    path: "",
    component: LayoutComponent,
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
    canActivate: [AuthGuard],
  },
  {
    path: "pages",
    loadChildren: () =>
      import("./extrapages/extrapages.module").then((m) => m.ExtrapagesModule),
    canActivate: [AuthGuard],
  },
  {
    path: "panel",
    loadChildren: () =>
      import("./account/auth/auto-login/auto-login.module").then(
        (m) => m.AutoLoginModule
      ),
  },
  { path: "refiere", component: CommingSoonComponent },
  { path: "**", component: CommingSoonComponent },
  // { path: "**", component: Page404Component },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
