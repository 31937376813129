<footer class="text-center py-5">
  <img src="assets/zafe/Logo/SVG/logo-blanco.svg" alt="zafe-white.png" />
  <p class="col-sm-12 text-white text-bold">
    {{ year }} &copy; zafe By Tecnotrust.
  </p>
</footer>

<!-- <footer *ngIf="!isMobile" class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="text-sm-center d-none d-sm-block">
          <img
            src="assets/zafe/Logo/PNG/footer_logo.png"
            alt="Zafe by Tecnotrust"
            loading="lazy"
          />
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="text-sm-end d-none d-sm-block font-size-12">
          <div>
            <strong>zafe</strong> by
            <a class="text-black" href="https://tecnotrust.com" target="_blank">
              Tecnotrust
            </a>
          </div>
          <div class="social-networks">
            <i class="fab fa-facebook-square"></i>
            <i class="fab fa-instagram"></i>
            <i class="fab fa-linkedin-in"></i>
          </div>
        </div>
      </div>

      <div class="col-sm-12">&copy;{{ year }}</div>
      <div class="col-sm-12">&copy;Developed by Tecnotrust.</div>
    </div>
  </div>
</footer> -->
